import axios from "axios";
import { useState, useEffect } from "react";
import { isNullEmptyOrUndefined, areEqualLowerCaseStrings } from "../utils/StringUtils";

/**
 * Utility function used to determine if the hook should load the IP and Location data.
 *
 * Used to prevent the end points being hit when in development environment.
 * The non calling of the APIs in development can be overridden in the .env settings by setting OVERRIDE_LOCATION_RENDERING_IN_DEV to true;
 */
const shouldLoadIpGeoData = () => {
  const DEVELOPMENT_ENV = "development";
  const activeEnvironment = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || DEVELOPMENT_ENV;

  if (process.env.OVERRIDE_LOCATION_RENDERING_IN_DEV === "true") {
    return true;
  }

  return !areEqualLowerCaseStrings(DEVELOPMENT_ENV, activeEnvironment);
};

/**
 * Default IP Geo Data object.
 *
 * Used to populate the state on initialization and to render some data when in the development environment
 */
const defaultIpGeoLocationData = {
  ip: "000.000.000.000",
  version: "IPv4",
  city: "Dev Town",
  region: "DevState",
  region_code: "DV",
  country_name: "United States Of America",
  country_code: "US",
  country_code_iso3: "USA",
  continent_code: "US",
  in_eu: false,
  postal: "11111",
  isLoading: true,
};

/**
 * Generates the user IP Geo Dataset.
 *
 * The ip based geolocation based data is generated by a third party service defined in the IP_GEO_LOCATION_SERVICE_URL environment variable.
 *
 * The service's response data is then mapped to the ipGeoData state object. Not all data is taken, only what is requested.
 *
 * @returns {object} An object that contains location data such as postal code, state and city. It also return
 * the user ip address and a loading status to reflect if the data has been safely loaded.
 */
function useIpGeoData() {
  const [isLoadingGeoData, setIsLoadingGeoData] = useState(true);
  const [ipGeoLocationData, setIpGeoLocationData] = useState(defaultIpGeoLocationData);

  useEffect(() => {
    const fetchData = async () => {
      const ipGeoLocationServiceUrl = process.env.IP_GEO_LOCATION_SERVICE_URL;

      axios
        .get(ipGeoLocationServiceUrl)
        .then((response) => {
          const userIpGeoLocationServiceData = response.data;

          setIpGeoLocationData(userIpGeoLocationServiceData);
          setIsLoadingGeoData(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (shouldLoadIpGeoData()) {
      fetchData();
    } else {
      setIsLoadingGeoData(false);
    }
  }, []);

  const userIpGeoLocationData = {
    ipAddress: ipGeoLocationData.ip,
    ipVersion: ipGeoLocationData.version,
    city: ipGeoLocationData.city,
    regionName: ipGeoLocationData.region,
    regionCode: ipGeoLocationData.region_code,
    countryName: ipGeoLocationData.country_name,
    countryCode: ipGeoLocationData.country_code,
    countryCodeISO3: ipGeoLocationData.country_code_iso3,
    continentCode: ipGeoLocationData.continent_code,
    isEUCountry: ipGeoLocationData.in_eu,
    postalCode: ipGeoLocationData.postal,
    isLoading: isLoadingGeoData,
  };

  /**
   * Utility function used to equality check the current country field value.
   *
   * @returns {boolean} True if the country field is equal to "US" (case independent) otherwise false;
   */
  userIpGeoLocationData.isUSBasedIpAddress = function () {
    if (isNullEmptyOrUndefined(this.countryCode)) return false;

    const US_COUNTRY_CODE = "us";
    return this.countryCode.toLowerCase() === US_COUNTRY_CODE;
  };

  return userIpGeoLocationData;
}

export default useIpGeoData;
