import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Favicon16 from "../../favicons/favicon-16x16.png";
import Favicon32 from "../../favicons/favicon-32x32.png";
import Favicon96 from "../../favicons/favicon-96x96.png";
import FaviconApple57 from "../../favicons/apple-icon-57x57.png";
import FaviconApple60 from "../../favicons/apple-icon-60x60.png";
import FaviconApple72 from "../../favicons/apple-icon-72x72.png";
import FaviconApple76 from "../../favicons/apple-icon-76x76.png";
import FaviconApple114 from "../../favicons/apple-icon-114x114.png";
import FaviconApple120 from "../../favicons/apple-icon-120x120.png";
import FaviconApple144 from "../../favicons/apple-icon-144x144.png";
import FaviconApple152 from "../../favicons/apple-icon-152x152.png";
import FaviconApple180 from "../../favicons/apple-icon-180x180.png";
import FaviconAndroid192 from "../../favicons/android-icon-192x192.png";
import FaviconMS144 from "../../favicons/ms-icon-144x144.png";

const SEO = ({ description, strapLine, meta, pageImagePath, title, isBlogPost }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            strapLine
            description
            siteUrl
            image
            twitterUrl
          }
        }
      }
    `
  );

  /**
   * Creates the Url to use for the various image based meta tags used in teh component.
   *
   * @param {object} siteMetadata An object containing the site's meta data properties.
   * @param {*} pageImagePath  A String containing a path to an image file. The file must be in the projects static directory and served on the root.
   *
   * @returns A String containing the generated image path to be used in the meta tags. If a valid image path is provided, it will be used otherwise fall back to site wide image. The url
   * used will depend on the environment you are running in. In development it will default to localhost:8000 otherwise the BASE_URL value will be used.
   */
  const createImageUrlFrom = (siteMetadata, pageImagePath) => {
    const imagePath = pageImagePath ?? siteMetadata.image;
    return `${siteMetadata.siteUrl}${imagePath}`;
  };

  const metaTitle = title || site.siteMetadata.title;
  const metaStrapLine = strapLine || site.siteMetadata.strapLine;
  const metaDescription = description || site.siteMetadata.description;
  const pageTitle = `${metaTitle} | ${metaStrapLine}`;
  const siteImage = createImageUrlFrom(site.siteMetadata, pageImagePath);

  return (
    <Helmet>
      {/* General tags */}
      <title>{pageTitle}</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={site.siteMetadata.siteUrl} />
      <meta name="image" content={siteImage} />
      {isBlogPost ? <meta property="og:type" content="article" /> : null}

      {/* OpenGraph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={siteImage} />

      {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={site.siteMetadata.twitterUrl} />
      <meta property="twitter:url" content={site.siteMetadata.siteUrl} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={siteImage} />

      <link rel="apple-touch-icon" sizes="57x57" href={FaviconApple57} />
      <link rel="apple-touch-icon" sizes="60x60" href={FaviconApple60} />
      <link rel="apple-touch-icon" sizes="72x72" href={FaviconApple72} />
      <link rel="apple-touch-icon" sizes="76x76" href={FaviconApple76} />
      <link rel="apple-touch-icon" sizes="114x114" href={FaviconApple114} />
      <link rel="apple-touch-icon" sizes="120x120" href={FaviconApple120} />
      <link rel="apple-touch-icon" sizes="144x144" href={FaviconApple144} />
      <link rel="apple-touch-icon" sizes="152x152" href={FaviconApple152} />
      <link rel="apple-touch-icon" sizes="180x180" href={FaviconApple180} />
      <link rel="icon" type="image/png" sizes="192x192" href={FaviconAndroid192} />
      <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
      <link rel="icon" type="image/png" sizes="96x96" href={Favicon96} />
      <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={FaviconMS144} />
      <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: ``,
  strapLine: ``,
  isBlogPost: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  strapLine: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  isBlogPost: PropTypes.bool,
};

export default SEO;
