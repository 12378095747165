import React from "react";
import { Link } from "gatsby";
import TCCWhiteLogo from "../../../svg/Footer/the-comparison-company-white-logo.svg";
import ComparacionWhiteLogo from "../../../svg/Footer/comparacion-white-logo.svg";
import { useStaticQuery, graphql } from "gatsby";
import TCCOutboundLink from "../TCCOutboundLink";

/**
 * Footer component used on all pages.
 */
export default () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            comparacionUrl
          }
        }
      }
    `
  );

  const copyrightYear = new Date().getFullYear();

  return (
    <footer className="py-3 bg-gray-500">
      <section className="container mx-auto">
        <div className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
          {/* left column */}
          <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-3/12 lg:my-px lg:px-px m lg:w-2/12 xl:my-px xl:px-px xl:w-2/12">
            <div className="flex items-start justify-center lg:justify-start">
              <Link title="Go to The Comparison Company Home Page" to="/">
                <TCCWhiteLogo className="w-full h-12" />
              </Link>
            </div>
          </div>
          {/* content  */}
          <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-6/12 lg:my-px lg:px-px lg:w-8/12 xl:my-px xl:px-px xl:w-8/12">
            {/* Content */}
            {/* Links */}
            <div className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
              <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-px xl:px-px xl:w-1/2">
                {/* left */}
                <div className="flex justify-center pt-3 pb-2 overflow-hidden lg:pl-6 lg:justify-start">
                  <Link className="text-white no-underline text-md hover:underline hover:text-yellow-500" to="/about-us">
                    About The Comparison Company
                  </Link>
                </div>

                <div className="flex justify-center pb-2 overflow-hidden lg:pl-6 lg:justify-start">
                  <Link className="text-white no-underline hover:underline hover:text-yellow-500" to="/terms-and-conditions">
                    Our Terms and Conditions
                  </Link>
                </div>

                <div className="flex justify-center pb-2 overflow-hidden lg:pl-6 lg:justify-start">
                  <Link className="text-white no-underline hover:underline hover:text-yellow-500" to="/privacy-policy">
                    Our Privacy Policy
                  </Link>
                </div>
              </div>

              <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-1/2 xl:my-px xl:px-px xl:w-1/2">
                {/* right */}
                <div className="flex justify-center pt-3 pb-2 overflow-hidden lg:pr-6 lg:justify-end">
                  <Link className="text-white no-underline hover:underline hover:text-yellow-500" to="/contact-us">
                    Contact The Comparison Company
                  </Link>
                </div>

                <div className="flex justify-center pb-2 overflow-hidden lg:pr-6 lg:justify-end">
                  <Link to="/partners-contact-us" className="text-white no-underline hover:underline hover:text-yellow-500" title="Read our guides to finding the best deals">
                    Partner & Supplier Sign Up
                  </Link>
                </div>

                <div className="flex justify-center pb-2 overflow-hidden lg:pr-6 lg:justify-end">
                  <Link className="text-white no-underline hover:underline hover:text-yellow-500" to="/sitemap">
                    Sitemap
                  </Link>
                </div>
              </div>
            </div>
            {/* Bog */}
            <div className="flex flex-wrap py-2 overflow-hidden">
              <div className="flex justify-center w-full overflow-hidden text-white xl:w-full">
                <Link to="/blog" className="px-4 text-white hover:text-yellow-500 hover:underline focus:text-white" title="Read our guides to finding the best deals">
                  The Comparison Company Blog
                </Link>
              </div>
            </div>
            {/* Copyright */}
            <div className="flex flex-wrap py-1 overflow-hidden">
              <div className="flex justify-center w-full overflow-hidden text-white xl:w-full">
                <p className="text-sm text-white">Copyright &copy; {copyrightYear} The Comparison Company</p>
              </div>
            </div>
          </div>

          {/* right column  */}
          <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-3/12 lg:my-px lg:px-px lg:w-2/12 xl:my-px xl:px-px xl:w-2/12">
            <div className="flex items-end justify-center border border-b-0 border-l-0 border-r-0 border-gray-500 border-t-1 lg:justify-end">
              <TCCOutboundLink className="pt-1" title="Go to our spanish language site Comparacion.com" href={site.siteMetadata.comparacionUrl}>
                <ComparacionWhiteLogo className="w-full" />
              </TCCOutboundLink>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};
