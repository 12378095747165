import React, { useState } from "react";
import PropTypes from "prop-types";
import TCCLogo from "../../../svg/the-comparison-company-logo.svg";
import { Input, Button, Notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import tccNotification from "../../utils/tccNotification";
import { isNullEmptyOrUndefined } from "../../utils/StringUtils";

/**
 * Returns an element that renders the Location Unavailable page to any blocked user.
 *
 * @param {Object} props An object containing the components data.
 */
const UnavailableLocation = (props) => {
  const { ipGeoData, windowSize } = props;
  const copyrightYear = new Date().getFullYear();

  const [serverState, setServerState] = useState({ submitting: false, status: null });
  const [emailAddress, setEmailAddress] = useState("");

  /**
   * Calculates the dimensions for the TCC logo based on the current screen viewpoint.
   *
   * @param {String} windowSize Contains the current viewpoint for the screen size.
   */
  const calculateLogoDimensionsFor = (windowSize) => {
    if (window === undefined || windowSize === "xlg" || windowSize === "lg") {
      return "50%";
    }

    if (windowSize === "md") {
      return "40%";
    }

    return "65%";
  };

  /**
   * Updates the emailAddress state value to the current value of the email address field.
   *
   * @param {String} evt A string containing the current value of the email address field.
   */
  const handleEmailAddressChange = (evt) => {
    setEmailAddress(evt.target.value);
  };

  /**
   * Generates and displays a TCC Notification using the provided arguments.
   *
   * @param {Object} notificationArguments An object containing the arguments to use to generate the TCC notification.
   */
  const displayNotificationUsing = (notificationArguments) => {
    Notification.open(notificationArguments);
  };

  /**
   * Generates the notification arguments that determine the TCC notification shown to the user based on the result of the form submission.
   *
   * Also cleans up state based on submission result.
   *
   * @param {Boolean} ok A Boolean that will be true if the form submitted as expected, without error.
   * @param {String} msg A String containing any message returned from the form submission attempt. Usually, an error message.
   * @param {Element} form An element representing the form that submitted the data.
   */
  const handleServerResponse = (ok, msg, form) => {
    const notificationArguments = ok
      ? tccNotification.buildSuccessNotificationArgsFrom("Thank You", "Your email address has been received & we will notify you once we launch our local service to you.")
      : tccNotification.buildFailedNotificationArgsFrom(
          "There was an error",
          "Thank you for trying to submit your email address. It seems there was a problem that was likely our fault. Please try again."
        );

    displayNotificationUsing(notificationArguments);

    setServerState({ submitting: false, status: { ok, msg } });

    if (ok) {
      form.reset();
      setEmailAddress("");
    }
  };

  /**
   * Form submission handler. Attempts to submit the form data to GetForm.io. The response of which is handled by handleServerResponse.
   *
   * @param {Element} e An element representing the form that submitted the data.
   */
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/8f60c5ad-a9fb-43fd-a710-dc00b5248e68",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Done", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  const logoSize = calculateLogoDimensionsFor(windowSize);

  return (
    <section className="container mx-auto">
      <div class="flex h-screen">
        <div class="m-auto text-center border p-0 lg:p-40 border-gray-landingPage200 bg-gray-landingPage100 w-11/12 lg:w-10/12">
          <TCCLogo className="inline-block w-full my-8 text-center" style={{ width: `${logoSize}`, height: `${logoSize}` }} />
          <h1 className="py-2 text-2xl lg:text-4xl text-blue-landingPage600">Not in service area</h1>
          <p className="w-full p-2 text-sm lg:text-base">The Comparison Company is currently only available in the U.S. and certain U.S territories.</p>
          <p className="mb-4 text-sm lg:text-base">We hope to launch in {ipGeoData.city} soon.</p>

          <h1 className="px-4 py-4 text-2xl lg:text-4xl text-blue-landingPage600">Be the first to know when we launch</h1>
          <form className="w-10/12 mx-auto lg:w-9/12" onSubmit={handleOnSubmit}>
            <label htmlFor="emailInput" className="text-gray-landingPage100" required="required">
              Email address
            </label>
            <Input
              type="email"
              value={emailAddress}
              onChange={handleEmailAddressChange}
              name="email"
              className="w-11/12 bg-white border rounded-md shadow-md outline-none border-blue-landingPage300 lg:w-full"
              id="emailInput"
              aria-describedby="emailHelp"
              size="large"
              placeholder="Email address"
              prefix={<MailOutlined className="text-gray-100" />}
            />
            <Button
              htmlType="submit"
              className="w-11/12 h-10 py-1 mx-auto my-4 text-lg font-bold text-white border rounded-md shadow-lg disabled:bg-green-landingPage700 disabled:border-green-landingPage700 disabled:text-white lg:w-full bg-green-landingPage800 border-green-landingPage800 hover:border-green-landingPage900 hover:bg-green-landingPage900 hover:text-white"
              disabled={isNullEmptyOrUndefined(emailAddress) || serverState.submitting}
            >
              Send
            </Button>
          </form>

          <p className="pt-8 pb-0 text-xs font-bold">Copyright &copy; {copyrightYear} The Comparison Company.</p>
          <p className="pt-0 pb-2 text-xs font-bold">All Rights Reserved.</p>
        </div>
      </div>
    </section>
  );
};

UnavailableLocation.propTypes = {
  props: PropTypes.shape({
    ipGeoData: PropTypes.object.isRequired,
    windowSize: PropTypes.object.isRequired,
  }),
};

export default UnavailableLocation;
