/**
 * Determines if the user should be blocked from entering the site based on two criteria:
 *
 * 1 - The environment is configured to check user location data. If not, anyone is welcome in and this function will return false. If location checking is enabled then point two is checked.
 * 2 - If user is not based in the USA based on their ip address based Geo Location data and their country is not whitelisted (See below) then this function returns true otherwise returns false.
 * 
 * NOTE - Use's process.env to determine if the environment should check the geo location of the users and any configured whitelist of countries to allow.
 * 
 * NOTE - The whitelist is a comma separated string of ISO3 country codes that can still access the site. To limit to te US only then leave as empty string. 
 *
 * @param {Object} ipGeoData A userIpGeoLocationData object containing the users Geo Location data based on their IP address (not the geolocation api from the browser).
 
 */
export const shouldBlockUserBasedOn = (ipGeoData) => {
  if (ipGeoData === undefined || ipGeoData === null) {
    throw new Error("Unable to determine user location as null or undefined ipGeoData object provided.");
  }

  const userLocationCheckingForEnvironmentEnabled = process.env.ENABLE_USER_LOCATION_CHECKING_FOR_ENVIRONMENT || false;
  const locationWhitelist = process.env.USER_LOCATION_CHECKING_FOR_ENVIRONMENT_WHITELIST || "";
  const whiteListedLocations = locationWhitelist.split(",");
  whiteListedLocations.push("USA");

  if (userLocationCheckingForEnvironmentEnabled === "true" && ipGeoData.isLoading === false) {
    if (!ipGeoData.isUSBasedIpAddress()) {
      return !whiteListedLocations.includes(ipGeoData.countryCodeISO3);
    }
  }

  return false;
};
