import { useState, useEffect } from "react";
import { window } from "browser-monads";

/**
 * Returns a responsive view modifier based on the provided windowWidth param.
 *
 * @param {any} windowWidth The current window width.
 * @returns {string}
 */
function getBreakPoint(windowWidth) {
  if (windowWidth) {
    if (windowWidth > 1280) {
      return "xlg";
    } else if (windowWidth >= 1024 && windowWidth < 1279) {
      return "lg";
    } else if (windowWidth >= 768 && windowWidth < 1023) {
      return "md";
    } else if (windowWidth > 640) {
      return "sm";
    }
  } else {
    return "lg";
  }
}

/**
 * Creates a state store to track the window width and a boolean which indicates if the
 * current window width is equal or larger than the lg responsive modifier.
 *
 * NOTE - isLargeScreenView will be depreciated at some point in the near future.
 *
 * @param {Boolean} debug - Optional - Defaults to false. If set to true will output hook data to console.debug.
 * @returns {object} Containers the windowSize and isLargeScreenView properties.
 */
function useWindowSize(debug = false) {
  const largeScreenViews = ["lg", "xlg"];

  const [windowSize, setWindowSize] = useState(getBreakPoint(window.innerWidth));
  const [isLargeScreenView, setLargeScreenView] = useState(largeScreenViews.includes(windowSize));

  useEffect(() => {
    function setSize() {
      setWindowSize(getBreakPoint(window.innerWidth));
      setLargeScreenView(largeScreenViews.includes(windowSize));
    }

    window.addEventListener("resize", setSize);
    return () => window.removeEventListener("resize", setSize);
  }, [setLargeScreenView, setWindowSize, largeScreenViews, windowSize]);

  if (debug) {
    console.debug("useWindowSize Hook windowSize = " + windowSize);
    console.debug("useWindowSize Hook isLargeScreenSize = " + isLargeScreenView);
  }

  return { windowSize: windowSize, isLargeScreenView: isLargeScreenView };
}

export default useWindowSize;
